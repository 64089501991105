<div
     class="app-modal "
     id="reusableModal"
     role="dialog"
     tabindex="-1">
   
      <div class="modal-header">
        <ng-content select="[modal-header]"></ng-content>
      </div>

      <div class="modal-body">
        <ng-content select="[modal-body]"></ng-content>
      </div>

      <div class="modal-footer">
        <ng-content select="[modal-footer]"></ng-content>
      </div>
</div>
