<div class="cd-filter display-lg static-side filter-is-visible" [ngClass]="{ 'filter-is-visible': showSearchForm }">
    <div class="filter-header">
        <i class="fa fa-sliders" aria-hidden="true"></i>Filter Events
    </div>
    <button role="button" class="cd-close" (click)="showFilter()"><span class="sr-only">Click to close slide-out search
            bar</span></button>
    <div class="filter-box">
        <div class="section-title">
            <h5 class="no-border"><i class="fa fa-fw fa-sliders" aria-hidden="true"></i>Filter Events</h5>
        </div>
        <div class="search-section">
            <div class="form-group">
                <label for="namesearch">
                    Course Name
                </label>
                <input placeholder="Search by Name" id="namesearch" class="form-control" (keydown.enter)="search()"
                    [formControl]="eventSearchForm.controls.courseName">
            </div>
            <div class="form-group">
                <label for="event_id">
                    Event ID
                </label>
                <number-input [placeholder]="'Search by Event ID'" [id]="'event_id'" [class]="'form-control'"
                    (onEnterPress)="search()" [formControlReference]="eventSearchForm.controls.eventId"></number-input>
                <span class="secondary-input-info">Organization's {{registryIdLabel}}</span>
            </div>
            <div class="form-group checkbox-group">
              <div class="app-checkbox box-md">
                <label for="PastEvents">
                  <input type="checkbox" id="PastEvents" (keydown.enter)="search()"
                         [formControl]="eventSearchForm.controls.daysPastEvent">
                  <span>Show Past Events (last {{eventSearchPast}} days)</span>
                </label>
              </div>
              <div class="app-checkbox box-md">
                <label for="FutureEvents">
                  <input type="checkbox" id="FutureEvents" (keydown.enter)="search()"
                         [formControl]="eventSearchForm.controls.daysFutureEvent">
                  <span>Show Future Events (next {{eventSearchFuture}} days)</span>
                </label>
              </div>
            </div>
        </div>
        <div class="app-buttons btm">
            <div class="btn-box">
                <button type="submit" id="btnFilter" class="btn primary-btn" (click)="search()" [disabled]="!isOnline">
                    <i class="fa fa-search fa-fw" aria-hidden="true"></i>Search
                </button>
                <button id="btnClearFilters" class="btn btn-gray btn-block" (click)="reset()">
                    <i class="fa fa-refresh fa-fw" aria-hidden="true"></i>Reset
                </button>
            </div>
        </div>
    </div>
</div>
