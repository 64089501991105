import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { take } from 'rxjs/operators';
import { UserContextService } from '../shared/service/user-context.service';

@Component({
  selector: 'app-login-callback',
  templateUrl: './login-callback.component.html',
})
export class LoginCallbackComponent implements OnInit {

  private readonly oidcSecurityService = inject(OidcSecurityService);

  private readonly userContextService = inject(UserContextService);

  constructor(
    private router: Router,
  ) { };

  ngOnInit() {
    // Subscribe to checkAuth() to determine user authentication status
    this.oidcSecurityService.checkAuth().pipe(
      take(1) // Automatically unsubscribe after the first emitted value
    ).subscribe({
      next: async ({ isAuthenticated }) => {
        if (isAuthenticated) {
          // Redirect to '/events/search' route if user is authenticated
          await this.userContextService.initialize();

          if (!this.userContextService.individualUserContext) {
            this.router.navigate(['invalid']); // Redirect to '/invalid' route if no Registry Account
          } else {
            this.router.navigate(['events/search']); //To event search if they do

          }
        } else {
          this.oidcSecurityService.authorize(); //Send them to authorize
          
        }
      },
    });
  };
}
