import { FormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router"; // Add this import statement

import { Injectable } from "@angular/core";
import { BaseSearchForm } from "../../shared/forms/base-search.form";
import { RegistryApiService } from "../../shared/service/registry-api.service";
import { PageResult } from "../../shared/models/page-result.model";
import { Attendee } from "../../shared/models/attendee.model";


@Injectable({ providedIn: 'root' })
export class AttendanceForm extends BaseSearchForm<Attendee> {

  private routeParam = this.route.snapshot.paramMap.get('eventId'); 

  private eventId!: number;

  public controls = {
    attendeeName: new FormControl<string>(''),
    attendeeId: new FormControl<number | null>(null),
    email: new FormControl<string>(''),
    pageNumber: new FormControl<number>(this.currentPage),
    pageSize: new FormControl<number>(this.pageSize),
  };


  constructor(
    private route: ActivatedRoute, 
    private registryApiService: RegistryApiService
  ) {

    super(true);
    if (this.routeParam) {
      this.eventId = parseInt(this.routeParam);
    }
    this.setupBaseForm();
   
  };

  protected override fetchServiceFunction(params: { [key: string]: unknown; }): Promise<PageResult<Attendee>> {
   
    return this.registryApiService.getEventRoster(this.eventId, params);
  };
}
