import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'attendance-page-header',
  standalone: true,
  imports: [],
  providers: [Router],
  templateUrl: './attendance-page-header.component.html',
})
export class AttendancePageHeader {
  constructor(private route: Router) { };

  goBackButton(): void {
    this.route.navigate(['/events/search']);
  };
}
