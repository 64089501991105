import { Component, Input, ViewChild, output } from "@angular/core";
import { ModalComponent } from "../../shared/components/modal/modal.component"; 
import { CommonModule } from "@angular/common";
import { Event } from "../../shared/models/event.model";
import { RegistryApiService } from "../../shared/service/registry-api.service";
import { AttendanceForm } from "../attendance-form/attendance-form.form";
import { Attendee } from "../../shared/models/attendee.model";
import { AttendeeType } from "../../shared/models/attendee-type.model";
import { FormsModule } from "@angular/forms";
import { smartLabelEnum } from "../../shared/enums/smart-label-enum";

@Component({
  selector: 'attendance-add-modal',
  templateUrl: './attendance-add-modal.component.html',
  standalone: true,
  imports: [ModalComponent, CommonModule,FormsModule],
  providers: [AttendanceForm]
})
export class AttendanceAddModalComponent {
  @Input({required:true}) public attendeeTypes: AttendeeType[] = [];

  @Input({required:true}) public attendee: Attendee = new Attendee();

  @Input({ required: true }) public registryLabel!: string;

  public exitModal = output<void>();

  public confirmAttendee = output<number | undefined>();

  @ViewChild('modal') public modal: ModalComponent | undefined;

  public selectedAttendeeType: AttendeeType | undefined;

  public noAttendeeSelected: boolean = false;


  get attendeeTypesExists(): boolean {
    return this.attendeeTypes.length > 0;
  };

  get attendeeTypeNotSelected(): boolean {
    return !(!this.selectedAttendeeType || this.attendeeTypesExists);
  };

  onConfirmClick(): void {
    if (this.attendeeTypes.length > 0) {
      //check if the type is imputed
      if (this.selectedAttendeeType === undefined) {
        return
      }
      this.confirmAttendee.emit(this.selectedAttendeeType.attendeeTypeId);
      this.exitModal.emit();
    } else {
      this.confirmAttendee.emit(undefined);
      this.exitModal.emit();
    }
  };
}
