import { Component, Input, OnInit, output } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';
import { BehaviorSubject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ScannerService } from '../../shared/service/scanner.service';
import { ModalComponent } from '../../shared/components/modal/modal.component';

@Component({
  selector: 'attendance-add-by-scanner',
  standalone: true,
  imports: [CommonModule, ZXingScannerModule,ModalComponent],
  providers: [ScannerService],
  templateUrl: './attendance-add-by-scanner.component.html',
})
export class AttendanceAddByScannerComponent {

  //keeping the service singleton and rendered
  @Input({required:true}) public scannerService!: ScannerService;

  @Input({ required: true }) public  eventId!: number;

  public exitModal = output<void>();

  constructor() { };

  scanSuccess(result: string): void {
    this.scannerService.scanSuccess(this.eventId,result)
  };

}
