
<div class="app-list-results" >
  <!--<div class="sort-box">
    <div class="form-inline">
      <div class="form-group">
        <label for="sortby">Sort by</label>
        <select class="form-control" id="sortby">
          <option value="Filter Orgs" selected>Event ID</option>
          <option value="Filter Orgs">Start Date</option>
        </select>
      <</div>
      <div class="form-group">
        <label for="sortorder">Sort by Order</label>
        <select id="sortorder" class="form-control">
          <option value="0: 0">Ascending</option>
          <option selected value="1: 1">Descending</option>
        </select>
      </div>
    </div>
  </div>-->
  
    <div class="pagination-box">
      <pageresult-counter *ngIf="showResultsLabel"
                          [pageNumber]="eventSearchForm.currentPage"
                          [pageSize]='eventSearchForm.pageSize'
                          [totalCount]="eventSearchForm.totalCount"></pageresult-counter>
    </div>
    <app-event-search-list-record *ngFor="let event of eventSearchForm.resultModels" [event]="event" (exitEventList)="exitEventList($event)"></app-event-search-list-record>
  <div>
    <div class="registry-warning app-width" *ngIf="showNoAvailableResultsLabel">
      <p class="alert-title">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>No Events Available
      </p>
      <p>There are no events ending today. Search to find events that recently ended. </p>
    </div>
    <div class="registry-warning app-width" *ngIf="showNoResultsFoundLabel">
      <p class="alert-title">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>No Events Found
      </p>
      <p>Please adjust your search criteria and search again.</p>
    </div>
  
  </div>
  <div class="pagination-box">
    <ngx-pager (pageChanged)="eventSearchForm.onPageChanged($event)"
               [pageNumber]="eventSearchForm.currentPage"
               [pageSize]='eventSearchForm.pageSize'
               [showPageCounter]='showResultsLabel'
               [totalCount]="eventSearchForm.totalCount"></ngx-pager>
  </div>  
</div>

 
