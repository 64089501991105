import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { InvalidRoleComponent } from './invalid-role/invalid-role.component'  ;
import { TrainerGuard } from './shared/guards/trainer.guard';
import { AuthGuard } from './shared/guards/auth.guard';
import { LoginCallbackComponent } from './login-callback/login-callback.component';
import {  AutoLoginPartialRoutesGuard, autoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { rosterAccessGuard } from './shared/guards/roster-access.guard';
import { AttendanceSummaryComponent } from './attendance/attendance-summary/attendance-summary.component';
import { EventSearchPageComponent } from './event-search/event-search-page/event-search-page.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'events/search',
    pathMatch: 'full',

  },
  {
    path: 'events/search',
    component: EventSearchPageComponent,
    canActivate: [AutoLoginPartialRoutesGuard, TrainerGuard],

  },
  {
    path: ':eventId/rosterView',
    component: AttendanceSummaryComponent,
    canActivate: [AutoLoginPartialRoutesGuard, TrainerGuard, rosterAccessGuard],
  },
  {
    path: 'invalid',
    component: InvalidRoleComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },

  {
    path: 'callback',
    component: LoginCallbackComponent,
  },
  {
    path: 'notfound',
    component: NotFoundPageComponent,
  },
  {
    path: '**',
    redirectTo: '/notfound',
    pathMatch: 'full',
  },

];

export class AppRoutingModule { }
