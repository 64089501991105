import { Component, OnInit, OnDestroy, output } from '@angular/core';

@Component({
  selector: 'app-invalid-role',
  templateUrl: './invalid-role.component.html',
  styles: [
    `
      .not-found-container {
        text-align: center;
        margin-top: 50px;
      }
    `,
  ],
})
export class InvalidRoleComponent {
}
