<attendance-page-header></attendance-page-header>
<div class="app-page">
    <div class="container-fluid full-width">
        <div class="administration-view center-dash">
            <div class="entity-detail event border-bottom">
                <div class="row">
                    <div class="col-sm-8">
                        <h4 class="name">
                            <span class="item-id labeled">Event Id<span class="number">{{eventId}}</span></span>
                            {{courseName}}
                        </h4>
                        <ul class="styled-list title-list">
                            <li>
                                <span class="info">
                                    <i class="fa fa-calendar fa-light"></i>Event Date<span>{{startDate+endDate}}</span>
                                </span>
                            </li>
                        </ul>

                    </div>
                    <div class="col-sm-4">
                    </div>
                </div>
            </div>
            <attendance-add [event]="event" [isOnline]="isOnline" (refreshList)="refreshListAfterAdd()"
                (verifyAttendee)="verifyAttendee($event)"></attendance-add>
            <attendance-list [refresh]="refresh" [verifyAttendeeEvent]="verifyAttendeeEvent" [eventId]="eventId"
                [attendanceForm]=attendanceForm [isOnline]="isOnline"></attendance-list>
        </div>
    </div>
</div>