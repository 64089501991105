import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Globals } from '../service/global.service';
//import { LayoutConfigurationService } from '../service/layout-configuration.service';

@Directive({
  selector: '[nwnCdnClient]',
  standalone: true,
})
export class CdnClientDirective implements OnInit/*, OnDestroy*/ {
    @Input('nwnCdnClient') imagePath: string | undefined;

    //private layoutConfigurationChanged: Subscription;

    constructor(
        private element: ElementRef,
    //    private layoutConfigurationService: LayoutConfigurationService
    ) {
        //this.layoutConfigurationChanged = this.layoutConfigurationService.configurationChange.subscribe(data => {
        //    if (data.configurationName === '_STYLESHEET_CDN_CLIENT_') {
        //        this.setStyleSheet(data.data);
        //    }
        //});
  };

  ngOnInit(): void {
    this.element.nativeElement.src = `${Globals._IMAGES_LINK}/${this.imagePath}`;
  };

  //ngOnDestroy(): void {
  //    this.layoutConfigurationChanged.unsubscribe();
  //};

    //private setStyleSheet = (cdnClientStyleSheet?: string) => {
    //    var cdnPath = cdnClientStyleSheet;
    //    if (!cdnPath && Globals._STYLESHEET_CDN_CLIENT_) {
    //        cdnPath = Globals._STYLESHEET_CDN_CLIENT_;
    //    }
    //};

}
