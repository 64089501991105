<div class="title-bar">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-10 col-md-9 col-sm-9 col-xs-9 col-tiny-12">
        <h1><i class="fa fa-users-line fa-light" aria-hidden="true"></i>Event Attendance</h1>
        <!--<h1> {{clientInfo}}</h1>-->
      </div>
      <div class="col-lg-2 col-md-3 col-sm-3 col-xs col-tiny-12">
        <div class="app-buttons tiny col-xs">
          <div class="btn-box right">
            <button  (click)="showFilter()" [disabled]="toggleShowFilterDisabled" role="button" aria-label="Reveals filters on mobile devices" class="cd-filter-trigger btn btn-white primary-color btn-icon"><i class="fa fa-fw fa-sliders" aria-hidden="true"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
