<div class="item">
    <h5 class="name">
        <span class="item-id labeled">{{registryIdLabel}}<span class="number">{{id || 'N/A'}}</span></span>
        {{fullName||"N/A"}}
    </h5>
    <div class="row">
        <div class="col-sm-12 col-md-8">
            <ul class="styled-list title-list">
                <li>
                    <span class="info">
                        <i class="fa fa-light fa-envelope" aria-hidden="true"></i>
                        Email<span>
                            {{email||"N/A"}}
                        </span>
                    </span>
                </li>
            </ul>
        </div>
        <div class="col-sm-12 col-md-4">
            <div class="checkbox-Switch" (click)="onVerifyClick()">
                <span>Verified Attendance</span>
                <input id="attendeeid_{{id}}" [checked]="isVerified" disabled name="verify_attendance"
                    type="checkbox" />
                <label for="attendeeid_{{id}}" class="label-success">Verify Attendance</label>
            </div>
        </div>
    </div>
</div>