<div class="title-bar">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-10 col-md-9 col-sm-9 col-xs-9 col-tiny-12">
        <h1><i class="fa fa-users-line fa-light" aria-hidden="true"></i>Manage Attendance</h1>
      </div>
      <div class="col-lg-2 col-md-3 col-sm-3 col-xs-3 col-tiny-12">
        <div class="app-buttons tiny">
          <div class="btn-box right">
            <button (click)="goBackButton()" role="button" class="btn btn-gray closepage-warning">Back</button>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
