<style>
  input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
</style>
<input 
       [placeholder]="placeholder"
       type="number"
       [id]="id"
       [class]="class"
       style="-moz-appearance: textfield"
       (keypress)="validateNumberInput($event)"
       [formControl]="formControlReference"
       (keydown.enter)="onEnterPressed()" />

      
