<base-modal>
  <div modal-header>
    <button type="button" (click)="exitModal.emit()" class="close" aria-label="Close"><span>×</span></button>
    <h4 id="confirmation_title" class="modal-title"><i class="fa fa-user-check fa-light"></i>VerifiyAttendee?</h4>
  </div>
  <div modal-body>
    <div class="confirmation-modal">
      <div class="instructions">
        Confirm the following attendee has completed this training:
      </div>
      <div class="entity-detail user">
        <h4 class="name">
          <span class="item-id labeled">{{registryIdLabel}}<span class="number">{{attendee.appId||"N/A"}}</span></span>
          {{attendee.firstName}} {{attendee.lastName}}
        </h4>
        <ul class="styled-list title-list">
          <li>
            <span class="info">
              <i class="fa fa-light fa-envelope"></i>
              Email<span>
                {{attendee.email||"N/A"}}
              </span>
            </span>
          </li>
        </ul>
      </div>

    </div>
  </div>
  <div modal-footer>
    <div class="row">
      <div class="twobutton-col">
        <button type="button" role="button" data-dismiss="modal" class="btn primary-btn btn-lg add-confirm-manual" (click)="onYesClick()">Yes</button>
      </div>
      <div class="twobutton-col">
        <button role="button" type="button" data-dismiss="modal" class="btn btn-gray btn-lg" (click)="exitModal.emit()">No</button>
      </div>
    </div>
  </div>
</base-modal>

