import { CommonModule, DecimalPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxPagerComponent } from '../../shared/components/ngx-pager/ngx-pager.component';
import { PageResultCounterComponent } from '../../shared/components/ngx-pager/pageresult-counter/pageresult-counter.component';
import { smartLabelEnum } from '../../shared/enums/smart-label-enum';
import { Attendee } from '../../shared/models/attendee.model';
import { RegistryApiService } from '../../shared/service/registry-api.service';
import { AttendanceForm } from '../attendance-form/attendance-form.form';
import { AttendanceVerifyModalComponent } from '../attendance-verify-modal/attendance-verify-modal.component';
import { AttendanceListRecordComponent } from './attendance-list-record/attendance-list-record.component';

@Component({
    selector: 'attendance-list',
    standalone: true,
    providers: [DecimalPipe, BsModalService, RegistryApiService],
    imports: [CommonModule, PageResultCounterComponent, NgxPagerComponent,
        AttendanceListRecordComponent, PageResultCounterComponent, AttendanceVerifyModalComponent,
    ],
    templateUrl: './attendance-list.component.html',
})
export class AttendanceListComponent implements OnInit, OnDestroy {

    @Input({ required: true }) public eventId!: number; // Define an verifyAttendeeModalRef property to receive the event ID

    @Input({ required: true }) public attendanceForm!: AttendanceForm;

    @Input({ required: true }) public refresh!: Subject<boolean>;

    @Input({ required: true }) public verifyAttendeeEvent!: Subject<Attendee>;

    @Input({ required: true }) isOnline: boolean = true;

    @ViewChild('verifyModalref', { read: TemplateRef }) public verifyAttendeeModal!: TemplateRef<any>;

    public showList: boolean = true;

    public verifyingAttendee: Attendee = new Attendee();

    public registryIdLabel: string = "N/A";

    private currentModal?: BsModalRef;

    private modalConfig = {
        animated: true,
        backdrop: 'static' as 'static',
        keyboard: false
    };

    constructor(private modalService: BsModalService, private registryApiService: RegistryApiService
    ) {
        this.registryApiService.getSmartLabel(smartLabelEnum.registryIDLabel).then((result) => { this.registryIdLabel = result });
    };

    async ngOnInit(): Promise<void> {
        this.attendanceForm?.reload(true);
        this.refresh.subscribe(() => {
            this.refreshListDataAfterAdd();
        });
        this.verifyAttendeeEvent.subscribe((attendee: Attendee) => {
            this.showVerifyModal({ attendee: attendee, modalRef: this.verifyAttendeeModal });
        });
    }

    ngOnDestroy(): void {
        this.refresh.unsubscribe();
        this.verifyAttendeeEvent.unsubscribe();
    }

    get showAlert(): boolean {
        return this.attendanceForm.totalCount === 0;
    }

    get showResultsLabel(): boolean {
        return this.attendanceForm.totalCount > 0;
    }

    // This is called when the refresh observable is called
    refreshListDataAfterAdd(): void {
        this.showList = false;
        this.attendanceForm.reload(false).then(() => {
            this.showList = true;
            Swal.fire({
                title: 'Success',
                text: 'The Attendee has been added!',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
        });
    };

    async verifyAttendee(attendee: Attendee): Promise<void> {
        this.registryApiService.verifyAttendee(attendee.appId, this.eventId).then(async (result: boolean) => {
            if (result && this.attendanceForm.resultModels) {
                var index = this.attendanceForm.resultModels.findIndex((curAttendee: Attendee) => curAttendee.appId === attendee.appId)
                if (this.attendanceForm.resultModels[index] !== undefined) {
                    this.attendanceForm.resultModels[index].attended = true;
                }
                Swal.fire({
                    title: 'Attendance Verified',
                    html: `
                    The following attendee was verified:
                    <br>${this.registryIdLabel} #${attendee.appId || 'N/A'}
                    <br>${attendee.firstName} ${attendee.lastName}
                    <br>Email: ${attendee.email||"N/A"}
                    `,
                    icon: 'success',
                    confirmButtonText: 'Ok'
                });
                this.exitModal();
                return;
            }
            this.displayError();
        }, (error) => {
            if (!this.isOnline) {
                navigator.serviceWorker.ready.then(() => {
                    const msg_chan = new MessageChannel();

                    msg_chan.port1.onmessage = event => {
                        if (event.data.type === 'registerUserOfflineSyncCallback') {
                            var reprocessAttendees: any[] = [];
                            event.data.offlineAttendees.forEach((element: { attendeeId: number; eventId: number; }) => {
                                reprocessAttendees.push(
                                    this.registryApiService.verifyAttendee(element.attendeeId, element.eventId)
                                );
                            });

                            Promise.all(reprocessAttendees).then(async (result: Array<boolean>) => {
                                Swal.fire({
                                    title: 'Offline Attendance Verified:',
                                    html: `Selected attendees when offline have been verified.`,
                                    icon: 'info',
                                    timer: 3000,
                                    timerProgressBar: true
                                });
                            });
                        }
                    }

                    navigator.serviceWorker.controller?.postMessage({
                        type: 'registerUserOfflineMessage',
                        attendeeId: attendee.appId,
                        eventId: this.eventId
                    },
                        [msg_chan.port2]
                    );
                });

                Swal.fire({
                    title: 'Offline Attendance Verified',
                    html: `
                    The following attendee was verified and will process when connection is re-established:
                    <br>${this.registryIdLabel} #${attendee.appId || 'N/A'}
                    <br>${attendee.firstName} ${attendee.lastName}
                    <br>Email: ${attendee.email||"N/A"}
                    `,
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });

                attendee.attended = true;
                this.exitModal();
            } else {
                this.displayError();
            }
        });
    };

    private displayError(): void {
        Swal.fire({
            title: 'ERROR',
            text: 'The Attendee could Not be verified!',
            icon: 'error',
            confirmButtonText: 'Ok'
        });
        this.exitModal();
    };

    exitModal(): void {
        this.currentModal?.hide();
    };

  showVerifyModal(data: { attendee: Attendee, modalRef: TemplateRef<any> }): void {
    this.verifyingAttendee = data.attendee;
    this.openModal(data.modalRef);
  };

    openModal(modalTemplate: TemplateRef<any>) {
        this.currentModal = this.modalService.show(modalTemplate, this.modalConfig);
    };
}
