import { Component, Input, output } from '@angular/core';
import { Attendee } from '../../shared/models/attendee.model';
import { ModalComponent } from '../../shared/components/modal/modal.component';

@Component({
  selector: 'attendance-verify-modal',
  standalone: true,
  imports: [ModalComponent],
  templateUrl: './attendance-verify-modal.component.html',
})
export class AttendanceVerifyModalComponent {
  
  @Input({ required: true }) public attendee: Attendee = new Attendee();

  @Input({ required:true }) public registryIdLabel!: string;
 
  public exitModal = output<void>();

  public verifyAttendee = output<Attendee>();

  onYesClick(): void {
    this.verifyAttendee.emit(this.attendee);
  };
}
