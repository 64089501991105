import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnDestroy, OnInit, output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NumberInputComponent } from '../../shared/components/number-input/number-input.component';
import { smartLabelEnum } from '../../shared/enums/smart-label-enum';
import { RegistryApiService } from '../../shared/service/registry-api.service';
import { OfflineService } from '../../shared/service/offline.service';
import { EventSearchForm } from './event-search.form';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-event-search-form',
    standalone: true,
    providers: [RegistryApiService],
    imports: [CommonModule, ReactiveFormsModule, NumberInputComponent],
    templateUrl: './event-search-form.component.html',
})
export class EventSearchFormComponent implements OnInit, OnDestroy {

    private unsubscribe = new Subject<void>();
    private readonly offlineService = inject(OfflineService);

    @Input() showSearchForm: boolean = false;
    @Input() eventSearchForm!: EventSearchForm;

    isOnline!: boolean;
    toggleShowFilter = output<void>();
    registryIdLabel: string = "N/A";

    constructor(public registryApiService: RegistryApiService) {
        this.registryApiService.getSmartLabel(smartLabelEnum.registryIDLabel).then((result) => { this.registryIdLabel = result });
    }

    ngOnInit(): void {
        this.offlineService.isOnline$
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
            next: (value: boolean) => {
                this.isOnline = value;
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    showFilter() {
        //toggles the form method that toggles if the filter should be shown or not in the small view
        this.toggleShowFilter.emit();
    };


    search = (): void => {
        //toggles the form method that toggles if the filter should be shown or not in the small view
        this.toggleShowFilter.emit();
        this.eventSearchForm.search();
    }

    reset() {
        this.eventSearchForm.reset();
    }

}
