import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { OfflineService } from '../../shared/service/offline.service';
import { PageHeaderComponent } from '../event-page-header/event-page-header.component';
import { EventSearchFormComponent } from '../event-search-form/event-search-form.component';
import { EventSearchForm } from '../event-search-form/event-search.form';
import { EventSearchListComponent } from '../event-search-list/event-search-list.component';

@Component({
    standalone: true,
    imports: [EventSearchListComponent, PageHeaderComponent, EventSearchFormComponent, CommonModule],
    providers: [EventSearchForm, OfflineService],
    selector: 'event-search-page',
    templateUrl: './event-search-page.component.html',
})
export class EventSearchPageComponent {

    constructor(
        public eventSearchForm: EventSearchForm
    ) { };
}
