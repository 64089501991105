import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, Subject, firstValueFrom, take, takeUntil } from 'rxjs';
import { CdnClientDirective } from './shared/directives/cdn-client.directive';
import { OfflineService } from './shared/service/offline.service';
import { UserContextService } from './shared/service/user-context.service';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        RouterOutlet,
        CommonModule,
        CdnClientDirective
    ],
    templateUrl: './app.component.html',
})

export class AppComponent implements OnInit {
    copyrightYear = 2024;
    title: string = '';
    name!: Observable<string | null>;
    dropdownOpen: boolean = false;
    isOnline: boolean = true;
    private readonly oidcSecurityService = inject(OidcSecurityService);
    private readonly userContextService = inject(UserContextService);
    private readonly offlineService = inject(OfflineService);
    private unsubscribe = new Subject<void>();

    constructor() { }

    // Initialize the component
    async ngOnInit(): Promise<void> {
        this.title = 'training-provider-mobile-app'; // Set the title of the component

        // Get the access token
        const token = await firstValueFrom(this.oidcSecurityService.getAccessToken());
        if (token) {
            await this.userContextService.initialize(); // Ensure the name is set initially
        }
        this.name = this.userContextService.name$; // Set the name property to the user's name
        this.offlineService.isOnline$
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
            next: (value: boolean) => {
                this.isOnline = value;
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    // Handle the logout button click event
    onLogoutButtonClicked(): void {
        this.oidcSecurityService.logoff().pipe(take(1)).subscribe();
        sessionStorage.clear();
    }

    // Toggle the dropdown menu
    toggleDropdown(event: Event) {
        event.preventDefault();
        this.dropdownOpen = !this.dropdownOpen;
    }
}
