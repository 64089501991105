import { Injectable } from "@angular/core";
import { BehaviorSubject, interval } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class OfflineService {
    private readonly intervarlOfflineCheckMS = 2000;
    private onlineStatus: boolean = true;

    private _isOnline$ = new BehaviorSubject<boolean>(this.onlineStatus);
    public isOnline$ = this._isOnline$.asObservable();

    constructor() {        
        interval(this.intervarlOfflineCheckMS).subscribe(() => {
            var checkOnlineStatus = window.navigator.onLine;

            if (checkOnlineStatus !== this.onlineStatus) {
                this.onlineStatus = checkOnlineStatus;
                this._isOnline$.next(this.onlineStatus);
            }
        });
    }
}