import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-not-found-page',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './not-found-page.component.html',
  styles: [
    `
      .not-found-container {
        text-align: center;
        margin-top: 50px;
      }
    `,
  ],
})
export class NotFoundPageComponent {

}
