import { Component,  Input,  OnInit,  output } from '@angular/core';
import { environment } from '../../../environments/environment';
import { clientDetection } from '../../shared/globalFunctions/globalFunctions';


@Component({
  selector: 'event-page-header',
  standalone: true,
  imports: [],
  templateUrl: './event-page-header.component.html',
})
export class PageHeaderComponent implements OnInit {
  @Input({required:true}) public toggleShowFilterDisabled: boolean = false;

  public toggleShowFilter = output<void>();

  public clientInfo: string = '';

  ngOnInit(): void {
    this.clientInfo = "Client: " + clientDetection() + "| API URL:" + environment.API_URL[clientDetection()];
  };

  //toggles the form method that toggles if the filter should be shown or not in the small view
  showFilter()  {
    this.toggleShowFilter.emit();
  };
}
