import { CommonModule, DatePipe } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Attendee } from '../../shared/models/attendee.model';
import { Event } from '../../shared/models/event.model';
import { UtcDatePipe } from '../../shared/pipes/utcdate.pipe';
import { OfflineService } from '../../shared/service/offline.service';
import { RegistryApiService } from '../../shared/service/registry-api.service';
import { AttendanceAddComponent } from '../attendance-add/attendance-add.component';
import { AttendanceForm } from '../attendance-form/attendance-form.form';
import { AttendanceListComponent } from '../attendance-list/attendance-list.component';
import { AttendancePageHeader } from '../attendance-page-header/attendance-page-header.component';

@Component({
    selector: 'app-attendance-summary',
    standalone: true,
    providers: [DatePipe, UtcDatePipe, AttendanceForm, Router],
    imports: [AttendancePageHeader, AttendanceListComponent, AttendanceAddComponent, CommonModule],
    templateUrl: './attendance-summary.component.html',
})
export class AttendanceSummaryComponent implements OnInit, OnDestroy {

    private unsubscribe = new Subject<void>();
    private readonly offlineService = inject(OfflineService);

    isOnline: boolean = true;
    event!: Event;
    courseName: string | undefined;
    eventId!: number;
    startDate: string = '';
    endDate: string = '';

    constructor(
        public registryApiService: RegistryApiService,
        private route: ActivatedRoute,
        private readonly datePipe: DatePipe,
        private readonly utcDatePipe: UtcDatePipe,
        public attendanceForm: AttendanceForm,
        public router: Router
    ) { }
    refresh: Subject<boolean> = new Subject();

    verifyAttendeeEvent: Subject<Attendee> = new Subject();

    refreshListAfterAdd(): void {
        this.refresh.next(true);
    }

    verifyAttendee(attendee: Attendee): void {
        this.verifyAttendeeEvent.next(attendee);
    }

    async ngOnInit(): Promise<void> {
        const id = this.route.snapshot.paramMap.get('eventId') ? this.route.snapshot.paramMap.get('eventId')! : 'ERROR';
        if (id === "ERROR") {
            this.router.navigate(['/notfound']);
        }
        this.eventId = parseInt(id);
        try {
            this.event = await this.registryApiService.getEvent(this.eventId);
            this.courseName = this.event?.title;
            this.initializeDate();
        } catch (error) {
            this.router.navigate(['/notfound']);
        }

        this.offlineService.isOnline$
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
            next: (value: boolean) => {
                this.isOnline = value;
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private initializeDate() {
        if (this.event) {
            if (!!this.event.endDate && !!this.event.startDate) {
                var endTime = !this.event.endTime ? '' : ' ' + this.event.endTime;
                var startTime = !this.event.startTime ? '' : ' ' + this.event.startTime;
                var endDateText = '';
                if (this.event.endDate !== this.event.startDate) {
                    endDateText = this.datePipe.transform(this.utcDatePipe.transform(this.event.endDate), 'shortDate') + "";

                } else if (startTime == endTime) {
                    endTime = '';
                }

                this.endDate = endDateText == '' && endTime == '' ? "" : " - " + endDateText + endTime;
                this.startDate = this.datePipe.transform(this.utcDatePipe.transform(this.event.startDate), 'shortDate') + startTime;
            }
        }
    }
}
