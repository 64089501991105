import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { RegistryApiService } from '../service/registry-api.service'; 


export const TrainerGuard: CanActivateFn = async () => {
  const registryApiService = inject(RegistryApiService);
  const router = inject(Router);
  let isTrainer: boolean = await registryApiService.doesUserHaveTrainerLevelIDAccess();
  if (isTrainer) {
    return true;
  } else {
    router.navigate(['invalid']);
    return false;
  }
};
