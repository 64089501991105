import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, output } from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { NumberInputComponent } from '../../shared/components/number-input/number-input.component';
import { TrainingProviderAddAttendeeStatusEnum } from '../../shared/enums/add-attendee-status';
import { smartLabelEnum } from '../../shared/enums/smart-label-enum';
import { AttendeePreview } from '../../shared/models/attendee-preview.model';
import { AttendeeType } from '../../shared/models/attendee-type.model';
import { Attendee } from '../../shared/models/attendee.model';
import { Event } from '../../shared/models/event.model';
import { RegistryApiService } from '../../shared/service/registry-api.service';
import { ScannerService } from '../../shared/service/scanner.service';
import { AttendanceAddByScannerComponent } from '../attendance-add-by-scanner/attendance-add-by-scanner.component';
import { AttendanceAddModalComponent } from '../attendance-add-modal/attendance-add-modal.component';

@Component({
    selector: 'attendance-add',
    standalone: true,
    providers: [
        RegistryApiService,
        BsModalService,
        ScannerService
    ],
    imports: [CommonModule, FormsModule, AttendanceAddModalComponent, NumberInputComponent, AttendanceAddByScannerComponent],
    templateUrl: './attendance-add.component.html'
})
export class AttendanceAddComponent implements OnInit, OnDestroy {

    @ViewChild('addAttendeeModalRef') public addAttendeeModalRef!: TemplateRef<any>;

    @Input({ required: true }) public event!: Event;
    @Input({ required: true }) isOnline: boolean = true;

    public refreshList = output<void>();

    public verifyAttendee = output<Attendee>();

    public attendeeId: FormControl<number | null> = new FormControl<number | null>(null);

    public hasClickedSearch: boolean = false;

    public showAddAttendeeModal: boolean = false;

    public modalLoaded: boolean = false;

    public showAddComponent: boolean = false;

    public attendeeTypes: AttendeeType[] = [];

    public attendee: Attendee = new Attendee();

    public registryLabel: string = "N/A";

    private currentModal?: BsModalRef;

    private modalConfig = {
        animated: true,
        backdrop: 'static' as 'static',
        keyboard: false
    };

    constructor(
        private registryApiService: RegistryApiService,
        private modalService: BsModalService,
        public scannerService: ScannerService
    ) { };

    ngOnInit(): void {
        this.scannerService.onScanSuccess.subscribe((addAttendeePreview: AttendeePreview) => {
            this.exitModal();
            this.attendeeId.setValue(addAttendeePreview.attendee.appId);

            this.handlePreviewResponse(addAttendeePreview, this.addAttendeeModalRef);
        });
    };

    ngOnDestroy(): void {
        this.scannerService.onScanSuccess.unsubscribe();
    };

    get inputPresent(): boolean {
        return !!this.attendeeId || this.attendeeId == 0;
    };

    get showAddAttendeeErrorLabel(): boolean {
        return !this.inputPresent && this.hasClickedSearch;
    };

    openModal(modalTemplate: TemplateRef<any>) {
        this.currentModal = this.modalService.show(modalTemplate, this.modalConfig);
    };

    exitModal = (): void => {
        this.currentModal?.hide();
    };

    showScanner(modalTemplate: TemplateRef<any>): void {
        this.scannerService.startScan();
        this.openModal(modalTemplate);
    }

    toggleAddAttendeeComponent(): void {
        this.showAddComponent = !this.showAddComponent;
    }

    async confirmAttendee(attendeeTypeId: number | undefined): Promise<void> {
        try {
            await this.registryApiService.addAttendee(this.event?.id!, this.attendeeId.value!, attendeeTypeId);
            this.refreshList.emit();
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: 'An error occurred while adding the attendee.',
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }
    };

    async addAttendee(modalTemplate: TemplateRef<any>): Promise<void> {
        this.hasClickedSearch = true;
        if (this.inputPresent && !!this.event?.id) {
            let response = await this.registryApiService.getAttendeePreview(this.event?.id, this.attendeeId.value!)
            this.handlePreviewResponse(response, modalTemplate);
        }
    }

    async handlePreviewResponse(response: AttendeePreview, modalTemplate: TemplateRef<any>) {
        switch (response.status) {
            case TrainingProviderAddAttendeeStatusEnum.Success:
                this.attendee = response.attendee;
                this.attendeeTypes = await this.registryApiService.getAttendeeTypes();
                this.registryLabel = await this.registryApiService.getSmartLabel(smartLabelEnum.registryIDLabel);
                this.openModal(modalTemplate);
                break;
            case TrainingProviderAddAttendeeStatusEnum.Unauthorized:
            case TrainingProviderAddAttendeeStatusEnum.UserIsLocked:
            case TrainingProviderAddAttendeeStatusEnum.UserDoesntExist:
                Swal.fire({
                    title: 'No accounts were found.',
                    text: 'Modify your search and try again.',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
                break;
            case TrainingProviderAddAttendeeStatusEnum.InvalidUserFromScan:
                Swal.fire({
                    title: 'No account was found.',
                    text: 'Valid account was not found.',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
                break;
            case TrainingProviderAddAttendeeStatusEnum.AttendeeAlreadyExists:
                var label;
                await this.registryApiService.getSmartLabel(smartLabelEnum.registryIDLabel).then((result) => { label = result });
                Swal.fire({
                    title: 'Already on Roster',
                    icon: 'error',
                    html: `The following attendee is already a verified attendee
              <br/>${label} #${response.attendee.appId || 'N/A'}
              <br/>Name: ${response.attendee.firstName + " " + response.attendee.lastName}
              <br/>Email: ${response.attendee.email || 'N/A'}`,
                    confirmButtonText: 'Ok'
                })
                break;
            case TrainingProviderAddAttendeeStatusEnum.AttendeeAlreadyExistsAndIsNotVerified:
                this.verifyAttendee.emit(response.attendee);
                break;
            case TrainingProviderAddAttendeeStatusEnum.InvalidQRCode:
                this.scannerService.startScan();
                Swal.fire({
                    title: 'Invalid QR code',
                    text: 'The QR code is invalid',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
                break;
            default:
                // this will occur if there is a status that is not handled, this should not happen
                Swal.fire({
                    title: 'Error',
                    text: 'An error occurred while adding the attendee.',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
                break;
        }
    }

}
