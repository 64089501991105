export const clientDetection = (): string => {
  const url = new URL(window.location.href);
  const state = url.hostname.split('-')[0];
  if (state === 'localhost') {
    return 'NY';
  }
  return state.toUpperCase();
};
export function encodeBase64(input: string): string {
  return btoa(input);
};
