export enum TrainingProviderAddAttendeeStatusEnum {
  Unauthorized = -1,
  AttendeeAlreadyExists = 0,
  Success = 1,
  UserDoesntExist = 2,
  UserIsLocked = 3,
  AttendeeAlreadyExistsAndIsNotVerified = 4,
  InvalidQRCode = 5,
  InvalidUserFromScan = 6,

};
