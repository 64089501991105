import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { clientDetection } from '../globalFunctions/globalFunctions';
import { RegistryApiService } from "./registry-api.service";
import { AttendeePreview } from "../models/attendee-preview.model";
import { TrainingProviderAddAttendeeStatusEnum } from "../enums/add-attendee-status";
import { Attendee } from "../models/attendee.model";

@Injectable({
  "providedIn": "root",

})
export class ScannerService {

  public onScanSuccess: Subject<AttendeePreview>;

  private readyToScan: boolean = false;
  
  constructor(
    private registryAPIService: RegistryApiService
  ) {
    this.onScanSuccess = new Subject<AttendeePreview>();
  };

  startScan(): void {
    this.readyToScan = true;
  };

  async scanSuccess(eventId:number,result: string): Promise<void> {
    if (this.readyToScan) {
      this.readyToScan = false;
      let attendeePreview: AttendeePreview = await this.registryAPIService.isValidQRCode(eventId, result);
        this.onScanSuccess.next(attendeePreview);
      }
  };
  
}
