import { CanActivateFn, Router } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { RegistryApiService } from '../service/registry-api.service';
import { firstValueFrom, from, map, take } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';

export const rosterAccessGuard: CanActivateFn = async (route, state) => {
  const registryApiService = inject(RegistryApiService);
  const router = inject(Router);
  const oidcSecurityService = inject(OidcSecurityService);

  try {
    const authResult = await firstValueFrom(oidcSecurityService.checkAuth());

    const event = await registryApiService.getEvent(route.params['eventId']);
    if (event && event.viewRoster) {
      return true;
    } else {
      await router.navigate(['/notfound']);
      return false;
    }
  } catch (error) {
    await router.navigate(['/notfound']);
    return false;
  }
};
