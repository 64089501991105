import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'base-modal',
  standalone: true,
  imports: [CommonModule,RouterOutlet],
  templateUrl: './modal.component.html',
})
export class ModalComponent { 
}
