<div class="col-md-4 col-md-push-8">
    <div class="dash-card MF-hide md-min" [ngClass]="{'hide-offline-content': !isOnline}">
        <div class="section">
            <h3 class="semi-bold">Scan Attendees</h3>
            <div class="instructions">
                Scan an attendee's membership QR code to verify attendees or add new attendees to this event.
            </div>
            <div class="app-buttons">
                <div class="btn-box right">
                    <button data-toggle="modal" data-target="#verifyattendee_confirmmodal" type="button" role="button"
                        class="btn primary-btn" (click)="showScanner(scanAddAttendeeModalRef)">Scan Attendee</button>
                    <button (click)="toggleAddAttendeeComponent()" type="button" role="button"
                        class="btn btn-white primary-color MF-slide-trigger md-max">Enter ID</button>
                </div>
            </div>
        </div>
    </div>
    <div class="dash-card MF-slide-out md-max"
        [ngClass]="{ 'MF-slide-is-visible': showAddComponent, 'hide-offline-content': !isOnline }">
        <div class="section">
            <button aria- type="button" role="button" class="MF-slide-close"
                (click)="toggleAddAttendeeComponent()">Click to close ID entry</button>
            <h3 class="semi-bold">Add Attendees by ID</h3>
            <div class="instructions">
                Enter the attendee's Registry ID number to add their verified attendance.
            </div>
            <div class="form-group">
                <label for="attendee_id">Attendee ID</label>
                <number-input [id]="'attendee_id'" [class]="'form-control input-lg'" [formControlReference]="attendeeId"
                    (onEnterPress)="addAttendee(addAttendeeModalRef)" />
                <div class="validation-callout" *ngIf="showAddAttendeeErrorLabel">
                    Enter valid ID number
                </div>
            </div>
            <div class="app-buttons">
                <div class="btn-box right">
                    <button data-toggle="modal" data-target="#newattendee_confirmmodal_manual"
                        (click)="addAttendee(addAttendeeModalRef)" (keydown.enter)="addAttendee(addAttendeeModalRef)"
                        type="button" role="button" class="btn primary-btn">Add Attendee</button>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #addAttendeeModalRef>
    <attendance-add-modal (exitModal)="exitModal()" (confirmAttendee)="confirmAttendee($event)"
        [attendeeTypes]="attendeeTypes" [attendee]="attendee" [registryLabel]="registryLabel">
    </attendance-add-modal>
</ng-template>
<ng-template #scanAddAttendeeModalRef>
    <attendance-add-by-scanner (exitModal)="exitModal()" [eventId]="event.id"
        [scannerService]="scannerService"></attendance-add-by-scanner>
</ng-template>