import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { clientDetection } from '../globalFunctions/globalFunctions';
import { IRegistryApiService } from '../interfaces/registry-api.interface';
import { AttendeePreview } from '../models/attendee-preview.model';
import { AttendeeType } from '../models/attendee-type.model';
import { Attendee } from '../models/attendee.model';
import { Event } from '../models/event.model';
import { IdentityUserContext } from '../models/identity-user-context.model';
import { PageResult } from '../models/page-result.model';

@Injectable({ providedIn: 'root' })
export class RegistryApiService implements IRegistryApiService {

    private httpHeaders;

    private daysPastNumber = 90;

    private apiRoute = "trainingproviderevents";

    private hasGottenSmartLabels = false;

    private smartLabels: Map<string, string> = new Map<string, string>();

    constructor(
        private readonly httpClient: HttpClient
    ) {
        this.httpHeaders = new HttpHeaders()
            .set('Accept-Language', 'en-US,en;q=0.9')
            .set('Cache-Control', 'no-cache')
            .set('Access-Control-Allow-Headers', 'Content-Type')
            .set('Access-Control-Allow-Origin', '*')
            .set('Pragma', 'no-cache');
    };

    setParams(params: { [key: string]: unknown }): HttpParams {
        let httpParams = new HttpParams();

        Object.entries(params).forEach(([key, value]) => {
            if (value) {
                httpParams = httpParams.set(key, value.toString());
            }
        });

        return httpParams;
    };

    private getUrl(endpoint: string): string {
        return `${environment.API_URL[clientDetection()]}/${endpoint}`;
    }

    getEvent(eventId: number): Promise<Event> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('daysShowPast', `${this.daysPastNumber}`)
        const url: string = this.getUrl(`${this.apiRoute}/${eventId}`);
        return firstValueFrom(this.httpClient.get<Event>(url, { headers: this.httpHeaders, params: httpParams }));
    };

    async getEvents(
        params?: {
            pageNumber?: number,
            pageSize?: number,
            courseName?: string,
            eventId?: number,
            daysPastEvent?: boolean
        }): Promise<PageResult<Event>> {
        const url: string = this.getUrl(`${this.apiRoute}/`);
        let httpParams = new HttpParams();
        if (params) {
            httpParams = this.setParams(params)
                .delete('daysPastEvent')
                .set('daysShowPast', params["daysPastEvent"] ? `${this.daysPastNumber}` : '');
        }
        return firstValueFrom(this.httpClient.get<PageResult<Event>>(url, { params: httpParams, headers: this.httpHeaders },));
    };

    async getEventRoster(eventId: number,
        params?: {
            pageNumber?: number,
            pageSize?: number,
            daysShowPast?: number,
        }): Promise<PageResult<Attendee>> {
        let httpParams = new HttpParams();
        if (params) {
            httpParams = this.setParams(params);
        }

        const url: string = this.getUrl(`${this.apiRoute}/${eventId}/roster`);
        return firstValueFrom(this.httpClient.get<PageResult<Attendee>>(url, { params: httpParams, headers: this.httpHeaders }));
    };

    async getAttendeeTypes(): Promise<AttendeeType[]> {
        const url: string = this.getUrl(`${this.apiRoute}/attendeetypes?activeOnly=true`);
        return firstValueFrom(this.httpClient.get<AttendeeType[]>(url, { headers: this.httpHeaders }));
    }

    async getAttendeePreview(eventId: number, attendeeId: number): Promise<AttendeePreview> {
        const url: string = this.getUrl(`${this.apiRoute}/${eventId}/roster/newattendee/preview`);
        let httpParams = new HttpParams().set('appId', attendeeId.toString());
        return firstValueFrom(this.httpClient.get<AttendeePreview>(url, { params: httpParams, headers: this.httpHeaders }));
    };

  async addAttendee(eventId: number, appId: number, attendeeTypeId: number | undefined): Promise<boolean> {
    const url: string = this.getUrl(`${this.apiRoute}/${eventId}/roster/add`);
    let httpParams = this.setParams({ appId: appId });
    if (attendeeTypeId) {
      httpParams = httpParams.set('attendeeTypeId', attendeeTypeId.toString());
    }
    return firstValueFrom(this.httpClient.post<boolean>(url, null, { params: httpParams, headers: this.httpHeaders },));
  };

    async getApplicant(applicantId: number): Promise<Attendee> {
        if (applicantId == this.getAppID()) {
            const url: string = this.getUrl(`apps/${applicantId}`);
            return firstValueFrom(this.httpClient.get<Attendee>(url, { headers: this.httpHeaders }));
        } else {
            const url: string = this.getUrl(`IndividualContactInfo/${applicantId}`);
            return firstValueFrom(this.httpClient.get<Attendee>(url, { headers: this.httpHeaders }));
        }
    };

    async verifyAttendee(attendeeId: number, eventId: number): Promise<boolean> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('attendeeId', `${attendeeId}`)
        const url: string = this.getUrl(`${this.apiRoute}/${eventId}/verifyattendee`);
        return firstValueFrom(this.httpClient.post<boolean>(url, null, { headers: this.httpHeaders, params: httpParams }));

    };

    async isValidQRCode(eventId: number, qrCode: string): Promise<AttendeePreview> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('qrCode', `${qrCode}`)
        const url: string = this.getUrl(`${this.apiRoute}/${eventId}/isvalidqrcode`);
        return firstValueFrom(this.httpClient.get<AttendeePreview>(url, { headers: this.httpHeaders, params: httpParams }));
    };

    async getIdentityUserContexts(): Promise<IdentityUserContext[]> {
        const url: string = this.getUrl(`identityusercontexts`);
        return firstValueFrom(this.httpClient.get<IdentityUserContext[]>(url, { headers: this.httpHeaders }));
    };

    async getAllSmartLabels(): Promise<void> {
        const url: string = this.getUrl(`${this.apiRoute}/getAllSmartLabels`);
        return firstValueFrom(this.httpClient.get<Record<string, string>>(url, { headers: this.httpHeaders })).then(response => {
            this.smartLabels = new Map(Object.entries(response).map(([key, value]) => [key, value]));
            this.hasGottenSmartLabels = true;
        });
    };

    async getSmartLabel(key: string): Promise<string> {
        if (!this.hasGottenSmartLabels) {
            await this.getAllSmartLabels().then(() => { })
        }
        return this.smartLabels.get(key) === undefined ? "N/A" : this.smartLabels.get(key)!;
    };

    async doesUserHaveTrainerLevelIDAccess(): Promise<boolean> {
        const url: string = this.getUrl(`${this.apiRoute}/doesuserhavetrainerlevelidaccess`);
        return firstValueFrom(this.httpClient.get<boolean>(url, { headers: this.httpHeaders }));
    };

    //These methods are not implemented but were used in the old zamaran app, they probably can be deprecated

    getAppID(): number {
        return 0;
    };

    isLoggedInUserAnOrganzation(): void { };

    storeIdentityUserContext(): IdentityUserContext[] {
        return [];
    };
}
