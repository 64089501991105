<div class="attendance-section">
    <div [ngClass]="{'col-md-8 col-md-pull-4': isOnline, 'col-md-12': !isOnline}">

        <h2>Attendance Verification</h2>
        <div class="registry-info" *ngIf="showAlert">
            <p class="alert-title">
                <i class="fa fa-info-circle" aria-hidden="true"></i>No Attendance
            </p>
            <p>There currently is no attendance for this event.</p>
        </div>
        <div *ngIf="!showAlert">
            <div class="instructions btm-gap-sm">
                <p>Select any existing attendees needing verification or add new attendees as needed.</p>
                <p class="help-text">Once attendance is verified, it can <strong>NOT</strong> be undone.</p>
            </div>
            <div class="list-view" *ngIf="showList">
                <div class="pagination-box" *ngIf="showResultsLabel">
                    <pageresult-counter [pageNumber]="attendanceForm.currentPage" [pageSize]='attendanceForm.pageSize'
                        [totalCount]="attendanceForm.totalCount"></pageresult-counter>
                </div>
                <div class="dash-card top-pad-xs">
                    <div class="items btm-gap">
                        <div class="item">
                            <div class="row">
                                <attendance-list-record *ngFor="let attendee of attendanceForm.resultModels"
                                    [attendee]="attendee" [verifyAttendeeModalRef]="verifyModalref"
                                    [isVerified]="attendee.attended" [registryIdLabel]="registryIdLabel"
                                    (showVerifyModal)="showVerifyModal($event)"></attendance-list-record>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pagination-box">
                <ngx-pager (pageChanged)="attendanceForm.onPageChanged($event)"
                    [pageNumber]="attendanceForm.currentPage" [pageSize]='attendanceForm.pageSize'
                    [showPageCounter]='showResultsLabel' [totalCount]="attendanceForm.totalCount"></ngx-pager>
            </div>
        </div>
    </div>
</div>
<ng-template #verifyModalref>
    <attendance-verify-modal [attendee]="verifyingAttendee" [registryIdLabel]="registryIdLabel"
        (exitModal)="exitModal()" (verifyAttendee)="verifyAttendee($event)"></attendance-verify-modal>
</ng-template>