import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { CommonModule, DecimalPipe } from '@angular/common';


@Component({
  standalone: true,
  imports: [CommonModule, DecimalPipe],
  selector: 'pageresult-counter',
  templateUrl: './pageresult-counter.component.html'
})
export class PageResultCounterComponent implements OnChanges, OnInit {

	/* Required Inputs */
  @Input({required:true}) pageNumber: number=0;
  @Input({required:true}) totalCount: number=0;

	/* Optional Inputs */
	@Input() pageSize: number = 25;

	/* Properties */
	message?: string;

	/* Smart Labels */
  private pagerCountMessageSmartLabel: string = "Showing [pager.countDisplay] out of [pager.totalCount]";

	/** Default, injectable constructor. */
	constructor(
        private readonly decimalPipe: DecimalPipe,
  ) {

  }

	// ------------------------------------------------------------------------------------------------------------------------------------
	// Properties
	// ------------------------------------------------------------------------------------------------------------------------------------

	/** Only show the component if the message to show has been successfully assembled. */
	get show(): boolean {

		if (!this.message || this.message.length === 0) { return false; }
		return true;
	}

	// ------------------------------------------------------------------------------------------------------------------------------------
	// Private Methods
	// ------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Gets the text to show for the page count.
	 */
  private getPageCountReplacementText = (): string => {

    const firstNumber = ((this.pageNumber - 1) * this.pageSize) + 1;
    let secondNumber = ((this.pageNumber - 1) * this.pageSize) + this.pageSize;

    if (secondNumber > this.totalCount || secondNumber < 0) { secondNumber = this.totalCount; } // The < 0 case will happen when we set pageSize to -1 to show all results
    if (secondNumber === 0) { return '0'; }
    if (secondNumber === firstNumber) { return secondNumber.toString(); }

    return `${this.decimalPipe.transform(firstNumber)}-${this.decimalPipe.transform(secondNumber)}`;
  }

    /**
     * Sets the message to show in the template.
     */
    private setMessage = (): void => {
        if ( this.totalCount > -1) {
            this.message = this.pagerCountMessageSmartLabel
                .replace('[pager.countDisplay]', this.getPageCountReplacementText())
                .replace('[pager.totalCount]', this.decimalPipe.transform(this.totalCount.toString()) || '');
        }
    }

	// ------------------------------------------------------------------------------------------------------------------------------------
	// ng Implementation Methods
	// ------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit(): void {
		this.setMessage();
	}

	ngOnChanges(changes: SimpleChanges): void {
    if (!this.pagerCountMessageSmartLabel) return;
		this.setMessage();
	}

} 
