import { FormGroup, UntypedFormControl } from '@angular/forms';
//import { SortDirection } from '@nwn/shared-library';
import { PageResult } from '../models/page-result.model';
//import { EnumUtilities } from '../classes/enum-utilities';
//import { Smartlabel } from '../enums/smartlabel.enum';
//import { Enum } from '../models/enum.model';

/**
 * Abstract base class for creating search forms in Angular applications.
 * Provides common functionality for search forms.
 */
export abstract class BaseSearchForm<T> {
  /**
   * Array to store search results.
   */
  public resultModels: Array<T> = new Array<T>();

  /**
   * Total count of search results.
   */
  public totalCount: number = 0;

  /**
   * Maximum number of items per page.
   */
  public pageSize: number = 25;
  /**
  * Current Page
  */
  public currentPage: number = 1;

  /**
  * Total count of pages.
  */
  public totalPages: number = 0;

  /**
   * Abstract property to define form controls. Derived classes must implement this.
   */
  public abstract controls: { [key: string]: UntypedFormControl };

  /**
   * Object to store search parameters and their initial values.
   */
  private searchParams: { [key: string]: unknown } = {};

  /**
   * The Angular FormGroup that contains the form controls.
   */
  public formGroup: FormGroup|undefined;

  /**
  * Abstract method for fetching data. This should be the specific service
  * call used to get the search results. Derived classes must implement this.
  */
  protected abstract fetchServiceFunction(params: { [key: string]: unknown }): Promise<PageResult<T>>;

  /**
   * Snapshot of form values for resetting the form.
   */
  private snapshot: { [key: string]: unknown } = {};

  /**
   * Flag indicating if the filter slider is open.
   */
  public isFilterSliderOpen: boolean = false;

  /**
   * Flag indicating if a search is currently in progress.
   */
  public isSearching = false;

  /**
   * Flag indicating if a search has been performed.
   */
  public hasSearched = false;

  /**
   * Array of sort directions.
   */
  //public sortDirections: Array<Enum> = new Array<Enum>();

  /**
   * Abstract property to define sort enum. Derived classes must implement this.
   */
  //protected abstract sortEnum?: { name: string, enum: object };

  /**
   * Array of sort fields.
   */
  //public sortFields: Array<Enum> = new Array<Enum>();

  /**
   * Constructor for BaseSearchForm.
   * @param searchOnReset Flag to determine whether to initiate a search on reset.
   */
  constructor(private searchOnReset: boolean = true) { }

  /**
   * Sets up the base form, initializes sort directions and sort fields.
   */
  protected setupBaseForm = (): void => {
    this.setupBaseFormNoReset();
    this.reset();
  };
  /**
   * Sets up the base form, initializes sort directions and sort fields but does not execute reset,
   * used to allow for storing of form values into session storage
   */
  protected setupBaseFormNoReset = (): void => {
    //this.sortDirections = EnumUtilities.getIdsAndNamesForSelect(SortDirection);
    //if (this.sortEnum) {
    //  this.sortFields = EnumUtilities.getIdsAndNamesForSelect(this.sortEnum.enum, undefined, undefined, false);
    //}

    this.formGroup = new FormGroup(this.controls);
    for (const controlName of Object.keys(this.controls)) {
      this.searchParams[controlName] = this.controls[controlName].value;
    }

    this.takeSnapshot();
  };

  /**
   * Gets whether the reset search state should be shown as an instructional message.
   */
  public get isResetSearchState(): boolean {
    return !this.searchOnReset && !this.hasSearched;
  }

  /**
   * Gets whether no records were found, and no search has been performed yet.
   */
  public get isRecordsFoundZero(): boolean {
    return !this.recordsExist && !this.hasSearched && !this.isResetSearchState;
  }

  /**
   * Gets whether no records were found, but a search has been performed.
   */
  public get isResultsFoundZero(): boolean {
    return !this.recordsExist && this.hasSearched;
  }

  /**
   * Gets whether there are records found in the search results.
   */
  public get recordsExist(): boolean {
    return this.totalCount > 0;
  }

  /**
   * Gets whether the reset button is disabled.
   */
  public get isResetDisabled(): boolean {
    return this.isSearching;
  }

  /**
   * Gets whether the search button is disabled.
   */
  public get isSearchDisabled(): boolean {
    return this.isSearching ||(!!this.formGroup && this.formGroup.invalid);
  }

  /**
   * Clears the search results and resets the total count to zero.
   */
  private clearSearchResults = (): void => {
    this.isFilterSliderOpen = false;
    this.resultModels = new Array<T>();
  };

  /**
   * Loads search results based on current search parameters.
   * Calls the fetchService method to initiate the search.
   */
  private loadResults = (): Promise<void> => {
    this.clearSearchResults();
    this.isSearching = true;
    this.setControlsEnabled(false);
    return new Promise<void>((resolve) => {
      this.fetchServiceFunction({
        ...this.searchParams,
      }).then((result: PageResult<T>) => {
        this.resultModels = result.data;
        this.totalCount = result.totalCount;
      }).finally(() => {
        this.isSearching = false;
        this.setControlsEnabled(true);
        resolve();
      });
    });
  };

  /**
   * Handles page change event and loads results for the selected page.
   */
  public onPageChanged = (event: { page: number, itemsPerPage: number }): void => {
    this.scrollToTop();
    this.currentPage = event.page;
    this.searchParams['pageNumber'] = event.page;
    this.controls['pageNumber'].setValue(event.page);
    this.loadResults();
  };

  /**
   * Handles sort change event and loads results with new sorting parameters.
   */
  //public onSortChange = (): void => {
  //  this.scrollToTop();
  //  this.searchParams.sortField = this.controls.sortField.value;
  //  this.searchParams.sortDirection = this.controls.sortDirection.value;
  //  this.controls.pageNumber.setValue(1);
  //  this.searchParams.pageNumber = 1;
  //  this.loadResults();
  //};

  /**
   * Scrolls to the top of the page.
   */
  private scrollToTop = (): void => {
    window.scrollTo(0, 0);
  };

  /**
   * Performs a search based on the current search parameters from the last search committed.
   * The search results are reset to the first page by default.
   * @param resetPageNumber Flag to determine if the page number should be reset on reload (default is true).
   */
  public reload = (resetPageNumber: boolean = true): Promise<void> => {
    this.scrollToTop();
    if (resetPageNumber) {
      this.currentPage = 1;
      this.controls['pageNumber'].setValue(1);
      this.searchParams['pageNumber'] = 1;
    }
    return this.loadResults();
  };

  /**
   * Resets the form to its initial state.
   * Clears the form, resets the search parameters, and optionally reloads results if `searchOnReset` is true.
   */
  public reset = (): void => {
    if (this.isResetDisabled || !this.formGroup) { return; }
    this.formGroup.reset(this.snapshot);
    this.scrollToTop();
    this.hasSearched = false;
    this.setSearchParams();

    this.totalCount = 0;
    if (this.searchOnReset) {
      this.currentPage = 1;
      this.loadResults();
    } else {
      this.clearSearchResults();
    }

  };

  /**
   * Performs a search based on current form values.
   * Initiates a new search with updated search parameters.
   */
  public search(): Promise<void>{
    if (this.isSearchDisabled) { return Promise.resolve(); }
   
    this.controls['pageNumber'].setValue(1);
    this.scrollToTop();
    this.hasSearched = true;
    this.setSearchParams();
    return this.loadResults();
  };

  /**
   * Gets the name of an enum as a string without spaces.
   */
  //public getEnumName(name: string): string {
  //  return name ? name.replace(/\s/g, '') : '';
  //}

  /**
   * Gets the label name for a sort field without spaces.
   */
  //public getSortFieldLabelName(name: string): string {
  //  return name ? (`${this.sortEnum.name}${name}`).replace(/\s/g, '') : '';
  //}

  /**
   * Gets the label name for a sort direction without spaces.
   */
  //public getSortDirectionLabelName(name: string): string {
  //  return name ? (Smartlabel.Generic_Prefix + name).replace(/\s/g, '') : '';
  //}

  /**
   * Toggles the filter slider open or closed.
   */
  public toggleFilterSlider(): void {
    this.isFilterSliderOpen = !this.isFilterSliderOpen;
  }

  /**
   * Sets the enabled state of form controls based on the provided flag.
   */
  private setControlsEnabled = (enabled: boolean): void => {
    for (const controlName of Object.keys(this.controls)) {
      if (this.controls[controlName]) {
        if (enabled) {
          this.controls[controlName].enable();
        } else {
          this.controls[controlName].disable();
        }
      }
    }
  };

  /**
   * Sets the search parameters based on current form values.
   */
  private setSearchParams = (): void => {
    for (const controlName of Object.keys(this.controls)) {
      this.searchParams[controlName] = this.controls[controlName]?.value;
    }
  };

  /**
   * Takes a snapshot of the current form values and marks the form as pristine and untouched.
   */
  private takeSnapshot = (): void => {
    if(!this.formGroup) { return; }
    this.snapshot = { ...this.formGroup.value };
    this.formGroup.markAsPristine();
    this.formGroup.markAsUntouched();
  };
}
