import { Injectable, inject } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { RegistryApiService } from './registry-api.service';
import { IdentityUserContext } from '../models/identity-user-context.model';
import { UserTypeEnum } from '../enums/user-type.enum';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserContextService {
  private nameSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public name$: Observable<string | null> = this.nameSubject.asObservable();
  private oidcSecurityService = inject(OidcSecurityService);
  public individualUserContext: IdentityUserContext | undefined;

  constructor(
    private registryAPI: RegistryApiService,
    private router: Router,
  ) { }

  // Initializes the user context
  async initialize(): Promise<void> {
    if (!this.individualUserContext) {
      const userContext = await this.getIndividualContext();
      this.nameSubject.next(userContext?.entityName ?? null); //Assign the name from the context to the nameSubject
    }
  }

  // Retrieves the individual user context
  public async getIndividualContext(): Promise<IdentityUserContext | null> {
    const identityUserContexts = await this.registryAPI.getIdentityUserContexts();
    this.individualUserContext = identityUserContexts.find(context => context.userType == UserTypeEnum.Individual);
    return this.individualUserContext || null;
  }
}
