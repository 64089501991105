import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { decode } from 'html-entities';
import { PageResultCounterComponent } from './pageresult-counter/pageresult-counter.component';
import { CommonModule, DecimalPipe } from '@angular/common';
import { PaginationModule } from 'ngx-bootstrap/pagination'; 


@Component({
  standalone: true,
    selector: 'ngx-pager',
  templateUrl: './ngx-pager.component.html',
  providers: [DecimalPipe],
  imports: [CommonModule, PageResultCounterComponent, PaginationModule],
})
export class NgxPagerComponent implements OnInit {

    /* Inputs */
    /** Current page number. */
    @Input() pageNumber: number = 1;
    /** Total count of all results, in all pages. */
    @Input() totalCount: number = 0;
    /** Hide the First/Last buttons. */
    @Input() hideBoundaryLinks: boolean = false;
    /** First button's text. */
    @Input() firstText: string = decode('&laquo;');
    /** Last button's text. */
    @Input() lastText: string = decode('&raquo;');
    /** Limits the maximum visible page numbers in the pager. */
    @Input() maxSize: number = 10;
    /** Next button's text. */
    @Input() nextText: string = decode('&rsaquo;');
    /** Number of results in each page. */
    @Input() pageSize: number = 25;
    /** Previous button's text. */
    @Input() previousText: string = decode('&lsaquo;');
    /** Show the page-counter component above the pager. */
    @Input() showPageCounter: boolean = true;


    /* Outputs */
    /** Fired when page is changed. $event:{page, itemsPerPage} equals to object with current page index and number of items per page */
    @Output() pageChanged: EventEmitter<{ page: number, itemsPerPage: number }> = new EventEmitter<{ page: number, itemsPerPage: number }>();

    /* Processing */
    private isProcessingMaxSizeSetting: boolean = false;
    private isProcessingPageSizeSetting: boolean = false;

    /** Application Settings */
    private applicationSettings = {
        defaultListPageSize: 25,
        defaultPaginationMaxPages: 1,
    };

    /** Default Injectable Constructor */
  constructor() {
    }

    // ------------------------------------------------------------------------------------------------------------------------------------
    // Public Properties
    // ------------------------------------------------------------------------------------------------------------------------------------

    /** Determines whether or not the component is in a state of processing. */
  get isProcessing(): boolean { 
        return this.isProcessingMaxSizeSetting
            || this.isProcessingPageSizeSetting;
    }

    /** TODO: Description */
  get showPagination(): boolean {
       return !(!this.pageSize) && this.pageSize < this.totalCount;
    }

    // ------------------------------------------------------------------------------------------------------------------------------------
    // Event Handlers
    // ------------------------------------------------------------------------------------------------------------------------------------

    /** Handles the Pager's (pageChanged) event. Emits to parent. */
  onPagerPageChanged = (event: { page: number, itemsPerPage: number }): void => {
    this.pageChanged.emit(event);
  };

    // ------------------------------------------------------------------------------------------------------------------------------------
    // Angular (ng) Implementation Methods
    // ------------------------------------------------------------------------------------------------------------------------------------

  ngOnInit(): void {
   }

} 
