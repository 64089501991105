import { Component, OnInit, output } from '@angular/core';
import { Input } from '@angular/core'; 
import { CommonModule, DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { Router } from '@angular/router'; // Import the Router module
import { UtcDatePipe } from '../../../shared/pipes/utcdate.pipe';
import {Event } from '../../../shared/models/event.model'; 
@Component({
  selector: 'app-event-search-list-record',
  standalone: true,
  providers: [DatePipe,UtcDatePipe],
  imports: [CommonModule],
  templateUrl: './event-search-list-record.component.html',
})
export class EventSearchListRecordComponent implements OnInit{

  @Input({required:true}) public event?: Event;

  public exitEventList = output<number>();

  public startDate: string = '';

  public endDate: string= '';

  constructor(
    private readonly datePipe: DatePipe,
    private readonly utcDatePipe: UtcDatePipe,
  ) { };

  ngOnInit() {
    if (this.event) {
      if (!!this.event.endDate && !!this.event.startDate) {
        var endTime = !this.event.endTime ? '' : ' ' + this.event.endTime;
        var startTime = !this.event.startTime ? '' : ' ' + this.event.startTime;
        var endDateText = '';
        if (this.event.endDate !== this.event.startDate) {
          endDateText = this.datePipe.transform(this.utcDatePipe.transform(this.event.endDate), 'shortDate') + "";

        } else if (startTime == endTime) {
          endTime = '';
        }
        this.endDate = endDateText == '' && endTime == '' ? "" : " - " + endDateText + endTime;
        this.startDate = this.datePipe.transform(this.utcDatePipe.transform(this.event.startDate), 'shortDate') + startTime;
      }
    }
  };

  viewEventRoster() {
    if (this.event!.viewRoster) {
      this.exitEventList.emit(this.event!.id); 
    } else {
        Swal.fire({
          title: 'Roster Unavailable',
          text: 'Roster management is not available for this event.',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
    }
  };

}
