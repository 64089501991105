import { Component, Input, OnInit, Query, input } from '@angular/core';
import { EventSearchListRecordComponent } from '../event-search-list/event-search-list-record/event-search-list-record.component'; 
import { CommonModule, DecimalPipe } from '@angular/common';
import { EventSearchForm } from '../event-search-form/event-search.form';
import { PageResultCounterComponent } from '../../shared/components/ngx-pager/pageresult-counter/pageresult-counter.component';
import { NgxPagerComponent } from '../../shared/components/ngx-pager/ngx-pager.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-event-search-list',
  standalone: true,
  templateUrl: './event-search-list.component.html',
  providers: [EventSearchForm,DecimalPipe,Router],
  imports: [EventSearchListRecordComponent,CommonModule,NgxPagerComponent,PageResultCounterComponent],
})
export class EventSearchListComponent implements OnInit {

  @Input({required:true}) public eventSearchForm!: EventSearchForm;

  constructor(
    private readonly router: Router
  ) { };

  async ngOnInit(): Promise<void> {
    this.eventSearchForm.search();
  };

  get showResultsLabel(): boolean {
    return this.eventSearchForm.totalCount > 0 && !this.eventSearchForm.isSearching;
  };

  get showNoAvailableResultsLabel(): boolean {
    return !this.eventSearchForm.recordsExist && !this.eventSearchForm.previousDaysPastEvent;
  };

  get showNoResultsFoundLabel(): boolean {
    return !this.eventSearchForm.recordsExist && this.eventSearchForm.previousDaysPastEvent;
  };

  exitEventList(eventId: number): void {
    if (!!this.eventSearchForm.controls['eventId'].value || this.eventSearchForm.controls['eventId'].value === 0) {
      sessionStorage.setItem('eventSearchEventId', this.eventSearchForm.controls['eventId'].value.toString());
    }
    if (!!this.eventSearchForm.controls['courseName'].value) {
      sessionStorage.setItem('eventSearchCourseName', this.eventSearchForm.controls['courseName'].value);
    }
    if (!!this.eventSearchForm.controls['daysPastEvent'].value) {
      sessionStorage.setItem('eventSearchdaysPastEvent', this.eventSearchForm.controls['daysPastEvent'].value.toString());
    } else {
      sessionStorage.setItem('eventSearchdaysPastEvent', 'false');
    }
    this.router.navigate([eventId, 'rosterView']);
  };
}
