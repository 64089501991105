<div class="pagination-box" >

	<pageresult-counter *ngIf="showPageCounter"
						[pageNumber]="pageNumber"
						[pageSize]="pageSize"
						[totalCount]="totalCount"></pageresult-counter>

	<pagination *ngIf="showPagination"
							(pageChanged)="onPagerPageChanged($event)"
							[boundaryLinks]="!hideBoundaryLinks"
							[firstText]="firstText"
							[itemsPerPage]="pageSize"
							[lastText]="lastText"
							[maxSize]="maxSize"
							[nextText]="nextText"
							[previousText]="previousText"
							[totalItems]="totalCount"></pagination>
	
</div>
