import { CommonModule } from '@angular/common';
import { Component, Input, output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'number-input',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './number-input.component.html',
})
export class NumberInputComponent {

  @Input() placeholder: string = "";

  @Input({ required: true }) id: string = "";

  @Input() class: string = "";

  @Input() ngModelReference: number | undefined;

  @Input() formControlReference!: FormControl<number | null>;

  onEnterPress = output<void>();

  onEnterPressed(): void {
    this.onEnterPress.emit()
  }


  validateNumberInput(event: any): boolean {
    return event.charCode >= 48 && event.charCode <= 57;
  }

}
