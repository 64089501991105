import { clientDetection } from "../app/shared/globalFunctions/globalFunctions";

export const environment = {
  production: false,
  API_URL: {
    'MI': 'https://mitest2.newworldnow.com/v7/api',
    'MN': 'https://mntest2.newworldnow.com/v7/api',
    'MO': 'https://motest2.newworldnow.com/v7/api',
    'MT': 'https://mttest2.newworldnow.com/v7/api',
    'NY': 'https://nytest2.newworldnow.com/v7/api',
    'OK': 'https://oktest2.newworldnow.com/v7/api',
    'PA': 'https://patest2.newworldnow.com/v7/api',
    'SC': 'https://sctest2.newworldnow.com/v7/api',
    'WI': 'https://witest2.newworldnow.com/v7/api'
  } as { [key: string]: string },
  authority: 'https://identitytest.newworldnow.com',
  redirectUrl: `https://${clientDetection().toLowerCase()}-pwadev.newworldnow.com/callback`,
  clientId: `Training Provider ${clientDetection()} PWA(Dev)`, //CREATE MAPPING BETWEEN REDIRECT AND CLIENTID
  imagesUrl: 'https://nwninsightcdn.azureedge.net/clients/InsightTrainerPWA/UAT/Insight/images/',
}
