<base-modal >
  <div modal-header >
    <button type="button" (click)="exitModal.emit()" class="close" aria-label="Close"><span>×</span></button>
    <h4 id="confirmation_title" class="modal-title"><i class="fa fa-user-check fa-light"></i>Scan Attendees</h4>
  </div>
  <div modal-body>
    <div class="confirmation-modal">
      <zxing-scanner (scanSuccess)="scanSuccess($event)"></zxing-scanner>
    </div>
    </div>
  <div modal-footer>
      <div class="row"></div>
        <div class="twobutton-col">
          <button role="button" type="button" data-dismiss="modal" class="btn btn-gray btn-lg" (click)="exitModal.emit()">Cancel</button>
        </div>
      </div>
</base-modal>

