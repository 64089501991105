import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, isDevMode, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withEnabledBlockingInitialNavigation } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { LogLevel, authInterceptor, provideAuth } from 'angular-auth-oidc-client';
import { environment } from '../environments/environment';
import { routes } from './app-routing.module';
import { clientDetection, encodeBase64 } from './shared/globalFunctions/globalFunctions';
import { RegistryApiService } from './shared/service/registry-api.service';
import { UserContextService } from './shared/service/user-context.service';

export const appConfig: ApplicationConfig = {
    providers: [
        provideHttpClient(withInterceptors([authInterceptor()])),
        provideAuth({
            config: {
                authority: environment.authority,
                authWellknownEndpointUrl: environment.authority + "/.well-known/openid-configuration",
                redirectUrl: environment.redirectUrl,
                postLogoutRedirectUri: environment.redirectUrl,
                clientId: encodeBase64(environment.clientId),
                scope: 'nwn.insight.webapi nwn.insight.identity.webapi offline_access email openid',
                secureRoutes: [environment.API_URL[clientDetection()]],
                responseType: 'code',
                silentRenew: true,
                useRefreshToken: true,
                logLevel: LogLevel.Debug,
                triggerAuthorizationResultEvent: true,


            }
        }),
        RegistryApiService,
        UserContextService,
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(routes, withEnabledBlockingInitialNavigation()),
        provideServiceWorker('offline-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000',
        }),
    ]
};
